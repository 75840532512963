import React from "react";
import serviceS1 from "@/images/mobile.png";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>App development</h2>
      <p>
        At Solxk, we specialize in transforming conceptual app ideas into
        tangible realities. Whether you represent a pioneering startup with a
        visionary concept or an established enterprise seeking innovation, our
        team of experienced developers and designers stands ready to actualize
        your aspirations. We firmly believe that successful app development
        transcends mere coding; it necessitates a profound understanding of your
        objectives, target audience, and unique challenges. From
        conceptualization through to deployment and ongoing support, we
        collaborate closely with you to craft applications that not only fulfill
        your technical specifications but also surpass your expectations in
        terms of design aesthetics, usability, and performance.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>planning & strategy</h3>
          <p>Services We Offer:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>iOS App Development &
              Android App Development
            </li>
            <li>
              <i className="fa fa-check-square"></i>Quality Assurance and
              Testing
            </li>
            <li>
              <i className="fa fa-check-square"></i>Cross-Platform Development
            </li>
            <li>
              <i className="fa fa-check-square"></i>UI/UX Design: Create
              intuitive, user-friendly
            </li>
            <li>
              <i className="fa fa-check-square"></i>App Maintenance and Support
            </li>
          </ul>
        </div>
      </div>
      <p>
        Whether you are a startup seeking to establish a strong brand identity
        or an established business aiming to refresh your visual presence,
        Solxk is here to assist you. Contact us today to discuss your design
        needs and take the first step towards transforming your brand into a
        visual masterpiece.
      </p>
      <p>
        At Solxk, we recognize that the foundation of a successful web
        development project lies in meticulous planning. Before writing a single
        line of code or designing a single pixel, we invest time in
        understanding your business goals, target audience, and unique
        requirements. Our comprehensive planning process ensures that every
        aspect of your project is carefully considered and executed with
        precision.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
